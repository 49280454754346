import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const FlatDetail = () => {
  const location = useLocation();
  let word = location.pathname.split("/");
  let price = word[word.length - 1];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const images = [
    {
      original: "/img/forsale.jpeg",
      thumbnail: "/img/forsale.jpeg",
    },
    {
      original: "/img/forsale.jpeg",
      thumbnail: "/img/forsale.jpeg",
    },
    {
      original: "/img/forsale.jpeg",
      thumbnail: "/img/forsale.jpeg",
    },
  ];

  return (
    <div className="flat-detail">
      <div className="page-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-title">PLOT DETAIL</h1>
              <h2 className="page-description">Plots for Investment</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="fd-top flat-detail-content">
              <div>
                <h3 className="flat-detail-title">Per Malra</h3>
                <p className="fd-address">
                  {" "}
                  <i className="fas fa-map-marker-alt"></i>
                  Outskirts of Srinagar
                </p>
              </div>
              <div>
                <span className="fd-price">{price} INR</span>
              </div>
            </div>
            <ImageGallery
              flickThreshold={0.5}
              slideDuration={0}
              items={images}
              showNav={false}
              showFullscreenButton={false}
              showPlayButton={false}
            />
            <div className="row">
              <div className="col-lg-8">
                <div className="fd-item">
                  <h4>Description</h4>
                  <p>
                    On the outskirts of Srinagar near Narbal chowk, in growing
                    colony with 14 feet road. Minimum plots available from 7
                    Malra to 8 Malra. Documentation is per Milkiyat and the
                    possession is generated exactly on the day of sale.
                  </p>
                </div>
                {/* <div className="fd-item fd-property-detail">
                                    <h4>Property Details</h4>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <span>Kitchen: </span>
                                            <span>1</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <span>All Rooms: </span>
                                            <span>5</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <span>Kitchen:  </span>
                                            <span>1</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <span>Kitchen: </span>
                                            <span>1</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <span>All Rooms: </span>
                                            <span>5</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <span>Kitchen:  </span>
                                            <span>1</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <span>Kitchen: </span>
                                            <span>1</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <span>All Rooms: </span>
                                            <span>5</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <span>Kitchen:  </span>
                                            <span>1</span>
                                        </div>
                                    </div>
                                </div> */}
                <div className="fd-item fd-features">
                  <h4>Property Details</h4>
                  <div className="row">
                    <div className="col-lg-4">
                      <i className="fa fa-check"></i>
                      <span>Documentation is per Milkiyat</span>
                    </div>
                    <div className="col-lg-4">
                      <i className="fa fa-check"></i>
                      <span>
                        Possession is generated on the same day of Purchase
                      </span>
                    </div>
                    <div className="col-lg-4">
                      <i className="fa fa-check"></i>
                      <span>14 Feet Road</span>
                    </div>
                  </div>
                </div>
                <div className="fd-item">
                  <h4>Maps</h4>
                  <iframe
                    src="https://maps.google.com/maps?q=191111 India,Narbal&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    title="location"
                    width="100%"
                    height="450"
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="fd-sidebar-item">
                  <h4>Recently Added</h4>

                  <div className="recently-item">
                    <img src="/img/product1.jpeg" alt="detail" width="50px" />
                    <span>Plots in Shahvilayat</span>
                  </div>
                  <div className="recently-item">
                    <img src="/img/product1.jpeg" alt="detail" width="50px" />
                    <span>Plots in Lawaypora</span>
                  </div>
                  <div className="recently-item">
                    <img src="/img/forsale.jpeg" alt="detail" width="50px" />
                    <span>Plots in Mustafabad</span>
                  </div>
                  <div className="recently-item">
                    <img src="/img/product1.jpeg" alt="detail" width="50px" />
                    <span>Plots in Shahavenue</span>
                  </div>
                  <div className="recently-item">
                    <img src="/img/forsale.jpeg" alt="detail" width="50px" />
                    <span>Plots in Gulshan Abad</span>
                  </div>
                </div>
                {/* <div className="fd-sidebar-item">
                                    <h4>Category</h4>
                                    <ul className="category-ul">
                                        <li>Category 1</li>
                                        <li>Category 2</li>
                                        <li>Category 3</li>
                                        <li>Category 4</li>
                                        <li>Category 5</li>
                                    </ul>
                                </div> */}
                {/* <div className="fd-sidebar-item">
                                    <h4>Recently Added</h4>
                                    <div className="recently-item">
                                        <img src="/img/product1.jpeg" alt="detail" width="50px" />
                                        <span>Lorem Ipsum Dolor</span>
                                    </div>
                                    <div className="recently-item">
                                        <img src="/img/product1.jpeg" alt="detail" width="50px" />
                                        <span>Lorem Ipsum Dolor</span>
                                    </div>
                                    <div className="recently-item">
                                        <img src="/img/product1.jpeg" alt="detail" width="50px" />
                                        <span>Lorem Ipsum Dolor</span>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlatDetail;
