import React from "react";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <img className="logo-footer" src={Logo} alt="logo" />
            <span className="footer-other-text d-block mt-3 mb-3">
              Invest now with Halal Investments and secure your future. Please
              visit our social media pages and follow.
              <br /> &copy; {new Date().getFullYear()} Halal Investments Private
              Limited{" "}
            </span>
            <div className="footer-social">
              <div className="footer-social-item">
                <a
                  href="https://www.facebook.com/Halalinvestments1?mibextid=LQQJ4d"
                  target="blank"
                >
                  <i className="fab fa-facebook"></i>
                </a>
              </div>
              {/* <div className="footer-social-item"><i className="fab fa-twitter"></i></div> */}
              <div className="footer-social-item">
                {" "}
                <a
                  href="https://www.instagram.com/halal_investments?igsh=MWNzc215NGpqczNrNA=="
                  target="blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <p className="footer-title">Menu</p>
            <ul className="footer-ul">
              <Link to="/">
                <li>Home</li>
              </Link>
              <Link to="about">
                <li>About</li>
              </Link>
              <Link to="contact">
                <li>Contact</li>
              </Link>
            </ul>
          </div>
          {/* <div className="col-lg-3 col-md-6">
                        <div>
                            <p className="footer-title">Categories</p>
                            <ul className="footer-ul">
                                <li>Category 1</li>
                                <li>Category 2</li>
                                <li>Category 3</li>
                                <li>Category 4</li>
                            </ul>
                        </div>
                    </div> */}

          <div className="col-lg-3 col-md-6">
            <p className="footer-title">Contact</p>
            <ul className="footer-ul">
              <li className="d-flex">
                <div className="footer-info-item">
                  <i className="fas fa-clock"></i>
                </div>{" "}
                <span>08:00-18:00</span>
              </li>
              <li className="d-flex">
                <div className="footer-info-item">
                  <i className="fas fa-envelope"></i>
                </div>{" "}
                <span>info@halalinvestments.co.in</span>
              </li>
              <li className="d-flex">
                <div className="footer-info-item">
                  <i className="fas fa-map-marker-alt"></i>
                </div>{" "}
                <span>Lawaypora Usmanabad Srinagar</span>
              </li>
              <li className="d-flex">
                <div className="footer-info-item">
                  <i className="fas fa-phone-alt"></i>
                </div>{" "}
                <span>+917006746013</span>
              </li>
            </ul>
          </div>
          {/*<div className="col-lg-3 col-md-6">
                        <p className="footer-title">Subscribe</p>
                        <span className="footer-other-text">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore dolore magna
                        </span>
                        <div className="subscribe-area mb-2 mt-2">
                            <input type="text" placeholder="Email" className="inp-footer w-100" />
                        </div>
                        <button className="btn-subscribe">Subscribe</button>
                    </div>*/}
        </div>
      </div>
    </section>
  );
};

export default Footer;
