import React, { Component } from "react";
// import Slider from "react-slick";
// import Title from "./Title"
// import BestFlatItem from "./BestFlatItem"

export default class BestFlatList extends Component {
  render() {
    // const title = {
    //     text: "PLOTS",
    //     description: "Plots around HMT area"
    // }
    // const settings = {
    //     infinite: true,
    //     speed: 1500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     autoPlay: true,
    //     arrows: false,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 3,
    //                 infinite: true,
    //                 dots: false
    //             }
    //         },
    //         {
    //             breakpoint: 800,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 3,
    //                 infinite: true,
    //                 dots: false
    //             }
    //         }
    //     ]
    return (
      <section className="section-best-estate">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* <Title title={title.text} description={title.description} /> */}
              {/* <Slider {...settings}>
                                <BestFlatItem flatState="For Rent"  />
                                <BestFlatItem flatState="For Sale"  />
                                <BestFlatItem flatState="For Rent"  />
                                <BestFlatItem flatState="For Rent"  />
                                <BestFlatItem flatState="For Sale"  />
                                <BestFlatItem flatState="For Rent"  />
                                
                            </Slider> */}
              <h2 className="title">PLOTS</h2>
              <h3 className="title-subheading">
                Plots available around HMT area
              </h3>
              <p className="title-subheading">
                Minimum plots available from 4 malra to 7 malra like 4 malra, 5
                malra, 6 malra and 7 malra.
              </p>
              <p className="title-subheading">
                <strong>
                  LOCATIONS : Shahvilayat, Shah Avenue, Mustafabad,
                  Mustafabad-2, Lawaypora, Umer Colony, Gulshan Abad, Greenhouse
                  Colony
                </strong>{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
