
import Title from "./Title"
import FlatItem from "./FlatItem"

const FlatList = () => {
    const title = {
        text: "Plots for Investments",
        description: "Invest now and secure your future"
    }
    return (
        <section className="section-all-re">
            <div className="container">
                <Title title={title.text} description={title.description} />
                <div className="row">
                    <FlatItem slug="details/140000" title="Outskirts of Srinagar, near Narbal Chowk" price='140000' />
                    <FlatItem slug="details/160000" title="Outskirts of Srinagar, near Narbal Chowk" price='160000' />
                    <FlatItem slug="details/200000" title="Outskirts of Srinagar, near Narbal Chowk" price='200000' />
                    <FlatItem slug="details/350000" title="Outskirts of Srinagar, near Narbal Chowk" price='350000' />
                </div>
            </div>
        </section>
    )

}

export default FlatList;