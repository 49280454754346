import FlatList from "./FlatList";
import Banner from "./Banner";
import React, { useEffect } from "react";
// import TeamList from "./TeamList"
// import References from "./References"
// import Subscribe from "./Subscribe"
import BestFlatList from "./BestFlatList";

const Home = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  return (
    <React.Fragment>
      <Banner />
      <FlatList />
      <BestFlatList />
      {/* <Subscribe/> */}
      {/* <TeamList/> */}
      {/* <References/> */}
    </React.Fragment>
  );
};

export default Home;
